import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" />
    <Container>
      <div style={{ maxWidth: 250, margin: "0 auto" }}>
        <Image />
      </div>
    </Container>
  </Layout>
)

export default IndexPage
